import axios, { AxiosResponse, AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
import {
  SignUpData,
  LoginData,
  ResetPasswordEmail,
  ResetPasswordData,
  User,
} from '../models/user.model';
import { Company } from '../models/company.model';

let token: string | null = null;

export class NWClient {
  public static signUp(data: SignUpData): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.API_URL}/api/user/signup/?fehost=${window.location.host}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static acceptInvitation(data: SignUpData): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.API_URL}/api/user/invited_link/?fehost=${window.location.host}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static logIn(data: LoginData): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.API_URL}/api/user/login/`, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static resetPasswordEmail(data: ResetPasswordEmail): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.API_URL}/api/user/reset/?fehost=${window.location.host}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static resendActivationCode(data: ResetPasswordEmail): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.API_URL}/api/user/send_activation_code/?fehost=${window.location.host}`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static resetPassword(
    data: ResetPasswordData,
    uidb64: string,
    token: string
  ): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.API_URL}/api/user/reset_confirmation/${uidb64}/${token}/`,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static confirmSignUp(uidb64: string, token: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/activate/${uidb64}/${token}/`)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static authorize(username: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const oidAuthorization = new Buffer(
        `${process.env.OID_CLIENT_ID}:${process.env.OID_CLIENT_SECRET}`
      ).toString('base64');

      axios
        .post(
          `${process.env.API_URL}/auth/token/`,
          `grant_type=client_credentials&username=${username}&password=${password}&scope=read write`,
          {
            headers: {
              Authorization: `Basic ${oidAuthorization}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then((response: AxiosResponse) => {
          token = response.data.access_token;
          resolve(token);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static stripeSetupIntent(token: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/stripe/setup-intent/create`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static subscribe(token: string, setupIntentId: string, userCount: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.API_URL}/api/stripe/subscription/subscribe/${setupIntentId}/${
            userCount + ''
          }/`,
          { headers: { Authorization: `Token ${token}` } }
        )
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static currentUser(token: string): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/user/current/`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data?.[0]);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static getCurrentCompany(token: string): Promise<Company[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/company/`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static downloadTemplate(token: string, id: number, filename: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/template/${id}/download_file/`, {
          headers: { Authorization: `Token ${token}` },
          responseType: 'arraybuffer',
        })
        .then((response: AxiosResponse) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          saveAs(blob, filename);
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static downloadDocument(token: string, id: number, filename: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/download-document-upload/${id}`, {
          headers: { Authorization: `Token ${token}` },
          responseType: 'arraybuffer',
        })
        .then((response: AxiosResponse) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          saveAs(blob, filename);
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static downloadTemplateInstance(
    token: string,
    id: number,
    filename: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/template-instance/${id}/download_instance/`, {
          headers: { Authorization: `Token ${token}` },
          responseType: 'arraybuffer',
        })
        .then((response: AxiosResponse) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          saveAs(blob, filename);
          resolve();
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static logOut(token: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.API_URL}/api/user/logout/`, null, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data?.[0]);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static list<T>(token: string, entity: string, params?: unknown): Promise<T[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/${entity}/`, {
          params: params,
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static get<T>(
    token: string,
    entity: string,
    id: number | string,
    params?: unknown
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.API_URL}/api/${entity}/${id + ''}/`, {
          params: params,
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static post<T>(
    token: string,
    entity: string,
    data: Omit<T, 'id'>,
    contentTypeObject?: boolean,
    urlParam?: string
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.API_URL}/api/${entity}/${urlParam ? urlParam : ''}`, data, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': contentTypeObject ? 'multipart/form-data' : 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static put<T>(
    token: string,
    entity: string,
    id: number,
    data: Omit<T, 'id'>,
    closingSlash?: boolean,
    contentTypeObject?: boolean
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.API_URL}/api/${entity}/${id}${closingSlash ? '/' : ''}`, data, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': contentTypeObject ? 'multipart/form-data' : 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static patch<T>(token: string, entity: string, id: number, data: Partial<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.API_URL}/api/${entity}/${id + ''}/`, data, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }

  public static delete<T>(
    token: string,
    entity: string,
    id: number | string,
    params?: unknown
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.API_URL}/api/${entity}/${id + ''}/`, {
          params: params,
          headers: { Authorization: `Token ${token}` },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}
